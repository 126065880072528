<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
        <ul class="navbar-nav mr-auto">
          <a class="navbar-brand" href="#">{{ getNavigationTitle() }}</a>
          <button
              type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{ toggled: $sidebar.showSidebar }"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
            <span class="navbar-toggler-bar burger-lines"></span>
          </button>
        </ul>
      </div>
        <!--        <ul class="nav navbar-nav mr-auto">-->
        <!--          <li class="nav-item">-->
        <!--            <a class="nav-link" href="#" data-toggle="dropdown">-->
        <!--              <i class="nc-icon nc-palette"></i>-->
        <!--            </a>-->
        <!--          </li>-->
        <!--          <base-dropdown tag="li">-->
        <!--            <template slot="title">-->
        <!--              <i class="nc-icon nc-planet"></i>-->
        <!--              <b class="caret"></b>-->
        <!--              <span class="notification">5</span>-->
        <!--            </template>-->
        <!--            <a class="dropdown-item" href="#">Notification 1</a>-->
        <!--            <a class="dropdown-item" href="#">Notification 2</a>-->
        <!--            <a class="dropdown-item" href="#">Notification 3</a>-->
        <!--            <a class="dropdown-item" href="#">Notification 4</a>-->
        <!--            <a class="dropdown-item" href="#">Another notification</a>-->
        <!--          </base-dropdown>-->
        <!--          <li class="nav-item">-->
        <!--            <a href="#" class="nav-link">-->
        <!--              <i class="nc-icon nc-zoom-split"></i>-->
        <!--              <span class="d-lg-block">&nbsp;Search</span>-->
        <!--            </a>-->
        <!--          </li>-->
        <!--        </ul>-->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item hidden">
          <router-link class="nav-link" to="/login">
            User
          </router-link>
        </li>
      </ul>
          <!--          <base-dropdown title="Dropdown">-->
          <!--            <a class="dropdown-item" href="#">Action</a>-->
          <!--            <a class="dropdown-item" href="#">Another action</a>-->
          <!--            <a class="dropdown-item" href="#">Something</a>-->
          <!--            <a class="dropdown-item" href="#">Another action</a>-->
          <!--            <a class="dropdown-item" href="#">Something</a>-->
          <!--            <div class="divider"></div>-->
          <!--            <a class="dropdown-item" href="#">Separated link</a>-->
          <!--          </base-dropdown>-->
      <div
          v-if="hasSiteTopBarCenter()"
          class="mx-auto order-0"
      >
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <scaled-image id="navbar-top-center-img" :src="clientImgSrc('site-topbar-center.png')" :height="50"/>
          </li>
        </ul>
      </div>
      <div class="navbar-collapse collapse w-100 order-3">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <span v-if="usingLoginAuth()">
              <button
                  v-if="$store.getters.isLoggedIn"
                  class="btn btn-sm btn-danger"
                  @click="logout"
              >
                Log Out
              </button>
              <a
                  href="#"
                  @click="$router.push({ name: 'Login' })"
                  v-else
                  class="text-secondary"
              >Not Logged In
              </a>
            </span>
            <scaled-image class="push-left" :src="clientImgSrc('site-topbar-rightv2.png')" :height="50"/>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
// import BaseDropdown from "../components/BaseDropdown";
import FeaturesMixin from "@/mixins/features.mixin";
import ScaledImage from "@/components/ScaledImage";

import "@clients/src/assets/js/dashforge.js";

// Dashforge styles
import "@clients/src/assets/css/dashforge.css";
import "@clients/src/assets/css/dashforge.auth.css";

export default {
  components: {
    ScaledImage
    // BaseDropdown
  },
  mixins: [FeaturesMixin],
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    userEmail() {
      if (this.$store.getters.isLoggedIn) {
        return this.$store.getters.user.email;
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      activeNotifications: false
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.dispatch("logout");
      this.$store.dispatch("startNewInquiry");
      if (this.$router.history.current.name !== "Login") {
        this.$router.push({ name: "Login" });
      }
    },
    usingLoginAuth() {
      return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
    },
    hasSiteTopBarCenter(){
      return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
    }
  }
};
</script>
