<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <h3 class="text-center tx-color-01 mg-b-5">Account Setup</h3>
      <p class="text-center tx-color-03 tx-16 mg-b-40">Welcome! Please set your account password below</p>
      <form v-on:submit.prevent>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="inpPassword"
                     :class="{'text-danger': passwordError}"
              >Password</label>
              <div class="input-group">
              <input
                  id="inpPassword"
                  class="form-control"
                  :class="{'border-danger': passwordError}"
                  :type="passwordVisible ? 'text' : 'password'"
                  autocomplete="new-password"
                  v-model="password"
                  tabindex="2"
                  placeholder="Enter your account password"
              />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" @mousedown="passwordVisible=true" @mouseleave="passwordVisible=false" @mouseup="passwordVisible=false">
                    <eye-icon v-if="!passwordVisible"></eye-icon><eye-off-icon v-else></eye-off-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="inpPasswordRepeat"
                     :class="{'text-danger': passwordError}"
              >Repeat Password</label>
              <div class="input-group">
              <input
                  id="inpPasswordRepeat"
                  class="form-control"
                  :class="{'border-danger': passwordError}"
                  :type="passwordRepeatVisible ? 'text' : 'password'"
                  autocomplete="new-password"
                  v-model="passwordRepeat"
                  tabindex="3"
                  placeholder="Enter your account password again"
              />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" @mousedown="passwordRepeatVisible=true" @mouseleave="passwordRepeatVisible=false" @mouseup="passwordRepeatVisible=false">
                  <eye-icon v-if="!passwordRepeatVisible"></eye-icon><eye-off-icon v-else></eye-off-icon>
                </button>
              </div>
              </div>
              <span v-for="(error, index) in errors" :key="index">
                    <small class="text-danger">{{ error }}</small>
                    <br />
                  </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn btn-brand-02 btn-block"
                    @click="setPassword"
                    :disabled="loading"
                    tabindex="4">
              Set Password and Login&nbsp;
              <span v-if="loading"><i class="fa fa-spinner fa-spin fa-2x"></i></span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { EyeIcon, EyeOffIcon } from 'vue-feather-icons';
export default {
  name: 'passwordExpired',
  components: {
    EyeIcon,
    EyeOffIcon
  },
  data: () => ({
    loading: false,
    errors: [],
    password: '',
    passwordRepeat: '',
    passwordError: false,
    passwordVisible: false,
    passwordRepeatVisible: false,
  }),
  props: {
    userHash: {
      type: String,
      required: true
    }
  },
  methods: {
    validateInputs() {
      // Clear error text
      this.errors = [];
      this.passwordError = false;

      if (this.password.length === 0 || this.passwordRepeat.length === 0) {
        this.errors = ['Please enter your account password'];
        this.passwordError = true;
        return false;
      }

      if (this.password !== this.passwordRepeat) {
        this.errors = ['Please retype your account password, as they do not match'];
        this.passwordError = true;
        return false;
      }

      return true;
    },
    async setPassword() {
      this.loading = true;
      if (!this.validateInputs()) {
        this.loading = false;
        return;
      }
      const data = await this.$store
          .dispatch(
              'setPassword',
              {'userHash': this.userHash, 'password': this.password}
          );
      if (data === undefined) {
        this.errors = [
          "We were not able to receive your password, please try again later. "
          + "(This may be because your device is not connected to the internet)"
        ];
        this.loading = false;
        return;
      } else if (data.success) {
        this.$bvToast.toast(
            'Successfully set your account password',
            {title: 'Account Setup', variant: 'success'}
        );
        await this.$store.dispatch(
          'login', 
          { email: data.user.email, password: this.password }
        );
        this.$emit('afterlogin');
      } else {
        console.error(data.errors);
        this.errors = data.errors;
      }
      this.loading = false;
    },
  },
}
</script>
