<template>
  <div class="row justify-content-center">
    <div :class="{'col-12': !embedded, 'col-12': embedded}">
      <card>
        <template v-if="!embedded" v-slot:header>
          <h2>Two Factor Authentication</h2>
          <em v-if="twoFactorSetup">Enter the code sent to your device</em>
          <em v-else>Please setup Two Factor Authentication below</em>
        </template>
          <form v-on:submit.prevent>
            <div v-if="shouldShowMethods && usingMobileMethod" class="row">
              <div class="col">
                <b-form-group label="Select how you would like to receive your One Time Password" v-slot="{ ariaDescribedBy }">
                  <b-form-radio v-model="twoFactorMethod"
                                :aria-describedby="ariaDescribedBy"
                                name="method-radios"
                                value="Email">
                    Email&nbsp;&nbsp;<span class="text-muted">{{ $store.getters.user.email }}</span>
                  </b-form-radio>
                  <b-form-radio v-if="usingMobileMethod" v-model="twoFactorMethod"
                                :aria-describedby="ariaDescribedBy"
                                name="method-radios"
                                value="Mobile">Mobile Phone
                  </b-form-radio>
                </b-form-group>
                <input type="email"
                       :value="$store.getters.user.email"
                       autocomplete="username email"
                       style="display: none;" />
              </div>
            </div>
            <div v-if="shouldShowMethods && !usingMobileMethod" class="row">
              <div class="col mb-2">
                <h6>Your One Time Password will be delivered via email to <em>{{ $store.getters.user.email }}</em></h6>
              </div>
            </div>
            <transition name="fade">
              <div v-if="shouldShowMethods && twoFactorMethod==='Mobile'" class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="inpMobileNumber">Mobile Phone Number</label>
                    <vue-tel-input id="inpMobileNumber"
                                   v-model="mobileNumber"
                                   :dropdownOptions="{showDialCodeInList: true, showDialCodeInSelection: true, showFlags: true}"
                                   :preferredCountries="['GB', 'US', 'JP', 'ES']"
                                   placeholder="Enter your phone number"
                                   :mode="'international'"
                    ></vue-tel-input>
                  </div>
                </div>
              </div>
            </transition>
            <div v-if="shouldShowMethods && usingMobileMethod" class="row mb-4">
              <div class="col">
                <button class="btn btn-info btn-block"
                        @click="sendCode"
                        :disabled="sendingCode || codeSent"
                        tabindex="4">
                  <send-icon></send-icon>&nbsp;
                  <span v-if="!codeSent">Send One Time Password</span>
                  <span v-else>Code sent!</span>
                  &nbsp;
                  <span v-if="sendingCode"><i class="fa fa-spinner fa-spin fa-2x"></i></span>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="inpOneTimePassword"
                         :class="{'text-danger': oneTimePasswordHasError}"
                  >Enter One Time Password</label>
                  <input
                      id="inpOneTimePassword"
                      class="form-control form-control-sm"
                      :class="{'border-danger': oneTimePasswordHasError}"
                      type="text"
                      autocomplete="one-time-code"
                      v-model="oneTimePassword"
                      tabindex="1"
                  />
                  <div v-if="error.length > 0" class="text-danger mt-2">{{ error }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn btn-brand-02 btn-block"
                        @click="authenticate"
                        :disabled="loading || !codeSent || sendingCode"
                        tabindex="4">
                  <unlock-icon></unlock-icon>&nbsp;
                  Authenticate&nbsp;
                  <span v-if="loading"><i class="fa fa-spinner fa-spin fa-2x"></i></span>
                </button>
              </div>
            </div>
          </form>
      </card>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import {SendIcon,UnlockIcon} from 'vue-feather-icons';
import AuthService from "@/services/auth.service";
import {SET_TWO_FACTOR_METHOD} from "@/store/mutation-types";
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  name: 'TwoFactor',
  components: { VueTelInput, SendIcon, UnlockIcon },
  data: () => ({
    loading: false,
    error: '',
    oneTimePassword: '',
    oneTimePasswordHasError: false,
    twoFactorMethod: 'Email',
    codeSent: false,
    sendingCode: false,
    mobileNumber: '',
    usingMobileMethod: false,
  }),
  props: {
    embedded: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters([
        'twoFactorSetup',
        'twoFactorAuthNeeded',
    ]),
    shouldShowMethods() {
      return !this.twoFactorSetup || this.embedded;
    }
  },
  methods: {
    validateInputs() {
      // Clear error text
      this.error = '';
      this.oneTimePasswordHasError = false;
      this.newPasswordError = false;

      if (this.oneTimePassword.length === 0) {
        this.error = 'Current Password is required to update your password';
        this.oneTimePasswordHasError = true;
        return false;
      }

      return true;
    },
    async authenticate() {
      this.loading = true;
      if (!this.validateInputs()) {
        this.loading = false;
        return;
      }
      const data = await this.$store.dispatch('authenticateTwoFactor', {oneTimePassword: this.oneTimePassword});
      if (data?.success) {
        if (this.embedded) {
          this.$bvToast.toast(
              'Successfully changed your Two Factor Authentication method to ' + this.twoFactorMethod,
              {title: 'Two Factor Auth', variant: 'success'}
          );
        } else {
          // TODO: Maybe go to the page they were trying to get to before they were redirected to 2FA
          let toRoute = 'New Inquiry';
          // if (this.$store.getters.clients.length === 1) {
          //   toRoute = 'Programmes';
          // }
          this.$router.push({name: toRoute});
        }
        this.$store.commit(SET_TWO_FACTOR_METHOD, {method: this.twoFactorMethod});
      } else {
        this.error = data.error;
      }
      this.loading = false;
    },
    async sendCode() {
      this.sendingCode = true;
      this.error = '';
      let mobile = this.mobileNumber;
      if (this.twoFactorMethod === 'Mobile') {
        mobile = mobile.replaceAll(' ', '');
      }
      const data = await AuthService.sendOTP(this.twoFactorMethod, mobile);
      if (data.success) {
        this.codeSent = true;
        this.$bvToast.toast(
            'A one time password has been sent to your ' + this.twoFactorMethod
            + ', please enter it into the box below ',
            {title: 'Two Factor Auth', variant: 'success'}
        );
      } else {
        this.error = data.error;
      }
      this.sendingCode = false;
    },
  },
  mounted() {
    // this.twoFactorMethod = this.$store.getters.twoFactorMethod;
    if (!this.embedded && (this.twoFactorSetup || !this.usingMobileMethod)) {
      this.sendCode();
    }
  }
}
</script>
